.custom-card {
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer; /* 鼠标形态变为点击形状 */
    width: 200px; /* 设置宽度 */
    height: 150px; /* 设置高度 */
  }
  
  .custom-card:hover {
    transform: scale(1.05); /* 突出效果 */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  }
  