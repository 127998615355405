.banner {
    background-color: #e6f7ff; /* 淡蓝色 */
    padding: 20px;
    text-align: left;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .banner h1 {
    margin: 0;
    color: #0050b3; /* 深蓝色 */
  }
  